import React from 'react'
import '../Styles/ProductDescription.css'
import { connect } from 'react-redux'
import { AddItem } from '../Models/Actions/AddItemAction'
import { Redirect } from 'react-router-dom'

class ProductDescription extends React.Component
{
    constructor(props)
    {
        super(props)
        this.title = props.product.info.productname
        this.description = props.product.info.productdescription
        this.price = props.product.info.productprice
        this.id = props.idproduct
        this.scent = props.product.info.prodcutscents
        this.productcategory = props.product.info.productcategory
        this.state = { units: 1, redirect: false }
    }

    renderRedirect()
    {
        if(this.state.redirect)
            return <Redirect to='/'/>
    }

    separate = (text) =>{
        return text.split(' - ')
    }

    renderJar()
    {
        if(this.productcategory === 'collection' || this.productcategory === 'speciality')
        {
            if(this.title === 'New Orleans Edition')
            {
                return (<div></div>)
            }
            return(
            <div>
                12 oz jar
            </div>
            )
        }
        return(
            <div></div>
        )
    }

    render(){
        return(
            <div className="product-description">
                <div className="title-container">
                    <h1>
                        {this.title}
                    </h1>
                </div>
                <div className="price-container">
                    ${this.price}
                </div>
                <div>
                    {this.renderJar()}
                </div>
                <div className="description-container">
                    {this.scent}
                    {this.separate(this.description).map((part, index) =>{
                        if(index === 1 && this.productcategory === 'collection')
                        {
                            return(
                                <p className='meaning-font'>
                                    {part}
                                </p>
                            )
                        }
                        return(<p>
                            {part}
                        </p>)
                    })}
                    {this.props.addon()}
                </div>
                <div className="button-container">
                    <button className='rounded-btn littlemargin' onClick={()=> {
                        this.props.AddItem({data: this.props.product, units: this.state.units})
                        this.setState((state)=> { return {...state, redirect: true}})
                    }}> Add to Cart</button>

                    <div className="unit-container littlemargin">
                        {this.state.units}
                    </div>

                    <div className="button-container littlemargin">
                        <button className='increment-btn' onClick={()=>{
                            this.setState((state) => ({...state, units: state.units + 1}))
                        }}>+</button>

                        <button className='decrement-btn' onClick={()=>{
                            this.setState(state => {
                                if(state.units <= 1)
                                    return ({units: 1})
                                return ({...state, units: state.units - 1})
                            })
                        }}>-</button>
                    </div>
                </div>

                {this.renderRedirect()}
            </div>
        )
    }
}

export default connect(null, { AddItem })(ProductDescription);