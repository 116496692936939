import coconutPalm from '../Assets/Images/Candles/CoconutPalm.jpg'
import CottonPatch from '../Assets/Images/Candles/CottonPatch.jpg'
import CrispLinen from '../Assets/Images/Candles/CrispLinen.jpg'
import PoundCake from '../Assets/Images/Candles/PoundCake.jpg'
import TropicalParadise from '../Assets/Images/Candles/TropicalParadise.jpg'
import VanillaRibbons from '../Assets/Images/Candles/VanillaRibbons.jpg'

import Collection_1 from '../Assets/Images/Candles/Collection_1.jpg'
import Collection_2 from '../Assets/Images/Candles/Collection_2.jpg'
import Collection_3 from '../Assets/Images/Candles/Collection_3.jpg'
import Collection_4 from '../Assets/Images/Candles/Collection_4.jpg'

import Peoples from '../Assets/Images/Candles/Peoples.jpg'
import HeartFoundation from '../Assets/Images/Candles/HeartFoundation.jpeg'
import HBCU from '../Assets/Images/Candles/HBCU.jpg'
import Generic from '../Assets/Images/Candles/Generic.jpg'
import Faith from '../Assets/Images/Candles/Faith_Single.jpg'
import ApplePie from '../Assets/Images/Candles/ApplePie.jpeg'
import goodness from '../Assets/Images/Candles/goodness.jpeg'
import goodness2 from '../Assets/Images/Candles/goodness_2.jpg'
import faith2 from '../Assets/Images/Candles/faith_2.jpg'
import CHRISTMAS_TRIO from '../Assets/Images/Candles/CHRISTMAS-TRIO.jpeg'

import kwanzaa from '../Assets/Images/Candles/kwanzaa.JPG'
import joy from '../Assets/Images/Candles/joy2.jpg'

import goodbyehello from '../Assets/Images/Candles/goodbye-hello.jpg'
import almondsheabutter from '../Assets/Images/Candles/almondsheabutter.jpeg'
import longsuffering from '../Assets/Images/Candles/longsuffering.jpg'
import lovetrio from '../Assets/Images/Candles/lovetrio.jpg'
import fruitSpirit from '../Assets/Images/Candles/FruitSpirit.jpg'
import birthday from '../Assets/Images/Candles/Birthday.jpeg'

import peace from '../Assets/Images/Candles/peace.jpg'
import gentleness from '../Assets/Images/Candles/gentleness.jpg'
import group from '../Assets/Images/Candles/grouptins.jpeg'
import luxbox from '../Assets/Images/Candles/luxbox.jpeg'
import understanding from '../Assets/Images/Candles/Understanding.jpeg'
import understanding_alt from '../Assets/Images/Candles/Understanding_alt.jpeg'
import meekness from '../Assets/Images/Candles/Meekness.jpeg'
import outdoor_1 from '../Assets/Images/Candles/Outdoor_1.jpeg'
import outdoor_2 from '../Assets/Images/Candles/Outdoor_2.jpeg'
import outdoor_3 from '../Assets/Images/Candles/Outdoor_3.jpg'
import jar from '../Assets/Images/Candles/Jar.jpg'
import wedding from '../Assets/Images/Candles/wedding.jpeg'
import charity from '../Assets/Images/Candles/charity.jpeg'
import grace from '../Assets/Images/Candles/grace.jpeg'
import temperance from '../Assets/Images/Candles/temperance.jpeg'

import earnedFitness from '../Assets/Images/Candles/earnedFitness.jpeg'
import happyFeelings from '../Assets/Images/Candles/happyFeelings.jpeg'
import mollyplace from '../Assets/Images/Candles/mollyplace.jpeg'
import whodat from '../Assets/Images/Candles/whodat.jpeg'
import cheer from '../Assets/Images/Candles/cheer_1.jpeg'
import winter_1 from '../Assets/Images/Candles/winter_1.jpg'
import winter_2 from '../Assets/Images/Candles/winter_2.jpg'
import kingcake from '../Assets/Images/Candles/kingcake.jpeg'
import kingcake2 from '../Assets/Images/Candles/kingcake2.jpeg'
import seafoodboil from '../Assets/Images/Candles/seafoodBoil.jpeg'
import bourbonSt from '../Assets/Images/Candles/bourbonSt.jpeg'
import divasindogtags from '../Assets/Images/Candles/divasIndogtags_small.jpeg'
import restore from '../Assets/Images/Candles/Restore.jpg'
import peabody from '../Assets/Images/Candles/peabody.jpeg'
import CHesterAlmondin from '../Assets/Images/Candles/CHesterAlmondine.jpeg'

export const imageMap = {

    'divasIndogtags.jpeg' : divasindogtags,
    'seafoodboil.jpeg' : seafoodboil,
    'bourbonSt.jpeg' : bourbonSt,
    'CoconutPalm.jpg': coconutPalm,
    'CottonPatch.jpg': CottonPatch,
    'CrispLinen.jpg': CrispLinen,
    'PoundCake.jpg': PoundCake,
    'TropicalParadise.jpg': TropicalParadise,
    'VanillaRibbons.jpg': VanillaRibbons,

    'Peoples.jpg': Peoples,
    'HeartFoundation.jpg': HeartFoundation,
    'HBCU.jpg': HBCU,
    'Generic.jpg': Generic,
    'Faith_Single.jpg': Faith,
    'ApplePie.jpeg' : ApplePie,
    'goodness.jpeg' : goodness,

    'faith_2.jpg': faith2,
    'goodness_2.jpg': goodness2,

    'Collection_1.jpg': Collection_1,
    'Collection_2.jpg': Collection_2,
    'Collection_3.jpg': Collection_3,
    'Collection_4.jpg': Collection_4,

    'CHRISTMAS-TRIO.jpeg': CHRISTMAS_TRIO,
    'kwanzaa.JPG': kwanzaa,
    'joy2.jpg': joy,
    'goodbye-hello.jpg' : goodbyehello,
    'almondsheabutter.jpeg' : almondsheabutter,
    'longsuffering.jpg': longsuffering,
    'lovetrio.jpg' : lovetrio,
    'FruitSpirit.jpg' : fruitSpirit,
    'Birthday.jpeg' : birthday,
    'peace.jpg' : peace,
    'gentleness.jpg' : gentleness,
    'grouptins.jpeg' : group,
    'luxbox.jpeg' : luxbox,
    'Understanding.jpeg' : understanding,
    'Understanding_alt.jpeg' : understanding_alt,
    'Meekness.jpeg' : meekness,
    'Outdoor_1.jpeg' : outdoor_1,
    'Outdoor_2.jpeg' : outdoor_2,
    'Outdoor_3.jpg' : outdoor_3,
    'Jar.jpg' : jar,
    'Wedding' : wedding,
    'charity' : charity,
    'grace' : grace,
    'temperance' : temperance,
    'earnedFitness' : earnedFitness,
    'happyFeelings' : happyFeelings,
    'mollyplace' : mollyplace,
    'whodat' : whodat,
    'cheer' : cheer,
    'winter_1' : winter_1,
    'winter_2' : winter_2,
    'kingcake.jpeg' : kingcake,
    'kingcake2.jpeg' : kingcake2,
    'restore.jpeg' : restore,
    'peabody.jpeg' : peabody,
    'CHesterAlmondin.jpeg' : CHesterAlmondin,
}
