import React from 'react'

class Icon extends React.Component{
    render()
    {
        return(
            <div style={{padding: '1vw'}}>
                <a href={this.props.link}>
                    <img src={this.props.image} alt="" width={this.props.size} height={this.props.size}/>
                </a>
            </div>
        )
    }
}

export default Icon