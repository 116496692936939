import React from 'react'
import ImageContainer from '../Components/ImageContainer'
import ProductDescription from '../Components//ProductDescription'
import Footer from '../Components/Footer'
import SmallTitle from '../Components/SmallTitle'
import '../Styles/ProductPage.css'
import { imageMap } from '../Models/ImageModel'
import LockHeader from '../Components/LockHeader'
import host from '../Assets/host/host'

class ProductPage extends React.Component
{
    constructor(prop)
    {
        super(prop)
        this.state = {
            hbcu: 'NULL',
            tin1: 'NULL',
            tin2: 'NULL',
            tin3: 'NULL',
            candle: {
                name : 'NULL',
                message : 'NULL',
                candle: 'NULL',
            },
        }
    }

    componentDidMount()
    {
        fetch(`${host}items/i/${this.props.match.params.id}`)
        .then(response => { return response.json() })
        .then(data => {
            this.setState((state)=>{return{...state, info: data[0]}})
        })
        .catch(err =>{
            console.log(err)
        })
    }

    renderImageContainer()
    {
        if(this.state.info != null)
        {
            return(
                <ImageContainer mainImage={imageMap[this.state.info.prodcutimagename]}/>
            )
        }
    }

    isAvailable = (startDate, endDate) =>
    {
        let sd = new Date(startDate.replace(' ', 'T'))
        let ed = new Date(endDate.replace(' ', 'T'))
        let cd = new Date()
        return (cd > sd && cd < ed)
    }

    onSelectChange = (event) =>{
        let val = event.target.value
        this.setState((state)=>{
            return{...state, 
                hbcu: val
            }
        })
    }

    onSelectChangeTin1 = (event) =>{
        let val = event.target.value
        this.setState(state =>{
            return{...state,
                tin1: val
            }
        })
    }
    
    onSelectChangeTin2 = (event) =>{
        let val = event.target.value
        this.setState(state =>{
            return{...state,
                tin2: val
            }
        })
    }

    onSelectChangeTin3 = (event) =>{
        let val = event.target.value
        this.setState(state =>{
            return{...state,
                tin3: val
            }
        })
    }

    onSelectChangeCandle = (event) =>{
        let val = event.target.value
        this.setState(state =>{
            let st = state
            st.candle.candle = val
            return{...state,
                candle: st.candle
            }
        })
    }

    onNameChange = (event) =>{
        let val = event.target.value
        this.setState(state =>{
            let st = state
            st.candle.name = val
            return{...state,
                candle: st.candle
            }
        })
    }

    onMessageChange = (event) =>{
        let val = event.target.value
        this.setState(state =>{
            let st = state
            st.candle.message = val
            return{...state,
                candle: st.candle
            }
        })
    }

    birthday = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname === 'Birthday')
            {
                return(
                    <div>
                        <h1>
                            Birthday Recipient
                        </h1>
                        <div> To the receiver</div>
                        <form action="">
                            <div>
                            Name:
                            <input type="text" maxLength="25" onChange={this.onNameChange}/>
                            </div>
                            
                            <div>
                            Message:
                            <input type="text" maxLength='35' onChange={this.onMessageChange}/>
                            </div>
                            
                            <div>
                            <select onChange={this.onSelectChangeCandle} value={this.state.candle.candle}>
                                <option value="NULL">Choose A Scent</option>
                                <option value="Tropical Paradise"> Tropical Paradise</option>
                                <option value="Crisp Linen"> Crisp Linen</option>
                                <option value="Pound Cake"> Pound Cake</option>
                                <option value="Cotton Patch"> Cotton Patch</option>
                                <option value="Vanilla Ribbons"> Vanilla Ribbons</option>
                                <option value="Coconut Palm"> Coconut Palm</option>
                            </select>
                            </div>
                        </form>
                    </div>
                )   
            }
        }
    }

    wedding = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname === 'Wedding')
            {
                return(
                    <div>
                        <h1>
                            Wedding Recipients
                        </h1>
                        <div> To the receiver</div>
                        <form action="">
                            <div>
                            Names:
                            <input type="text" maxLength="25" onChange={this.onNameChange}/>
                            </div>
                            
                            <div>
                            Message:
                            <input type="text" maxLength='35' onChange={this.onMessageChange}/>
                            </div>
                            
                            <div>
                            <select onChange={this.onSelectChangeCandle} value={this.state.candle.candle}>
                                <option value="NULL">Choose A Scent</option>
                                <option value="Tropical Paradise"> Tropical Paradise</option>
                                <option value="Crisp Linen"> Crisp Linen</option>
                                <option value="Pound Cake"> Pound Cake</option>
                                <option value="Cotton Patch"> Cotton Patch</option>
                                <option value="Vanilla Ribbons"> Vanilla Ribbons</option>
                                <option value="Coconut Palm"> Coconut Palm</option>
                            </select>
                            </div>
                        </form>
                    </div>
                )   
            }
        }
    }

    hbcuAddition = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname ==='Essence of Ebony'){
                return(
                    <div>
                        <h1>
                            HBCU
                        </h1>
                        <div className="hbcu-message">
                            Select A HBCU recipient
                        </div>
                        <form action="">
                            <select onChange={this.onSelectChange} value={this.state.hbcu}>
                                <option value="NULL">Choose A University</option>
                                <option value="Alabama State University">Alabama State University</option>
                                <option value="Alcorn State University">Alcorn State University</option>
                                <option value="Paul Quinn College">Paul Quinn College</option>
                                <option value="Southern University and AnM College">Southern University and A&M College</option>
                                <option value="Tuskegee University">Tuskegee University</option>
                            </select>
                        </form>
                    </div>
                )
            }
        }
    }

    CitronellaColoredJars = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname ==='Citronella Colored Jars'){
                return(
                    <div>
                        <h1>
                            Color
                        </h1>
                        <div className="hbcu-message">
                            Select Color
                        </div>
                        <form action="">
                            <select onChange={this.onSelectChange} value={this.state.tin1}>
                                <option value="NULL">Color</option>
                                <option value="Red">Red</option>
                                <option value="Blue">Blue</option>
                            </select>
                        </form>
                    </div>
                )
            }
        }
    }

    valentineAddition = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname ==='Love Trio'){
                return(
                    <div>
                        <h1>
                            Ménage a Trios'
                        </h1>
                        <div className="hbcu-message">
                            Select your Tins
                        </div>
                        <form action="">
                            <select onChange={this.onSelectChangeTin1} value={this.state.tin1}>
                                <option value="NULL">Choose A Tin</option>
                                <option value="Red Hot">Red Hot</option>
                                <option value="Be Mine">Be Mine</option>
                                <option value="Hugs">Hugs</option>
                                <option value="Adore">Adore</option>
                                <option value="All of Me">All of Me</option>
                                <option value="Voltage">Voltage</option>
                                <option value="Sweet Thang">Sweet Thang</option>
                            </select>
                        </form>

                        <form action="">
                            <select onChange={this.onSelectChangeTin2} value={this.state.tin2}>
                                <option value="NULL">Choose A Tin</option>
                                <option value="Red Hot">Red Hot</option>
                                <option value="Be Mine">Be Mine</option>
                                <option value="Hugs">Hugs</option>
                                <option value="Adore">Adore</option>
                                <option value="All of Me">All of Me</option>
                                <option value="Voltage">Voltage</option>
                                <option value="Sweet Thang">Sweet Thang</option>
                            </select>
                        </form>

                        <form action="">
                            <select onChange={this.onSelectChangeTin3} value={this.state.tin3}>
                                <option value="NULL">Choose A Tin</option>
                                <option value="Red Hot">Red Hot</option>
                                <option value="Be Mine">Be Mine</option>
                                <option value="Hugs">Hugs</option>
                                <option value="Adore">Adore</option>
                                <option value="All of Me">All of Me</option>
                                <option value="Voltage">Voltage</option>
                                <option value="Sweet Thang">Sweet Thang</option>
                            </select>
                        </form>
                    </div>
                )
            }
        }
    }

    ShabbyChicPatioTrioAddition = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname ==='Shabby Chic Patio Trio'){
                return(
                    <div>
                        <h1>
                            Trios
                        </h1>
                        <div className="hbcu-message">
                            Select your Jars
                        </div>
                        <form action="">
                            <select onChange={this.onSelectChangeTin1} value={this.state.tin1}>
                                <option value="NULL">Choose A Jar</option>
                                <option value="Red">Red</option>
                                <option value="Blue">Blue</option>
                                <option value="Green">Green</option>
                                <option value="Peach">Peach</option>
                            </select>
                        </form>

                        <form action="">
                            <select onChange={this.onSelectChangeTin2} value={this.state.tin2}>
                                <option value="NULL">Choose A Jar</option>
                                <option value="Red">Red</option>
                                <option value="Blue">Blue</option>
                                <option value="Green">Green</option>
                                <option value="Peach">Peach</option>
                            </select>
                        </form>

                        <form action="">
                            <select onChange={this.onSelectChangeTin3} value={this.state.tin3}>
                                <option value="NULL">Choose A Jar</option>
                                <option value="Red">Red</option>
                                <option value="Blue">Blue</option>
                                <option value="Green">Green</option>
                                <option value="Peach">Peach</option>
                            </select>
                        </form>
                    </div>
                )
            }
        }
    }

    twoCannikins = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname ==='Pick 2 Cannikins'){
                return(
                    <div>
                        <div>
                        Note: <div color='#ff0000'>if you order more than two cannikins, you must add them to your cart one order at a time please.</div>
                        </div>
                        <h1>
                            Pick 2 Cannikins
                        </h1>
                        <div className="hbcu-message">
                            Select your Tins
                        </div>
                        <form action="">
                            <select onChange={this.onSelectChangeTin1} value={this.state.tin1}>
                                <option value="NULL">Choose A Tin</option>
                                <option value="Almond Shea Butter">Almond Shea Butter</option>
                                <option value="Berry Blast">Berry Blast</option>
                                <option value="Coconut Palm">Coconut Palm</option>
                                <option value="Cotton Patch">Cotton Patch</option>
                                <option value="Crisp Linen">Crisp Linen</option>
                                <option value="Creme Brulee">Creme Brulee</option>
                                <option value="Lavender Fields">Lavender Fields</option>
                                <option value="Pound Cake">Pound Cake</option>
                                <option value="Mint Julep">Mint Julep</option>
                                <option value="Tropical Paradise">Tropical Paradise</option>
                                <option value="Vanilla Ribbons">Vanilla Ribbons</option>
                                <option value="Peach Passion">Peach Passion</option>
                                <option value="Eucalyptus">Eucalyptus</option>
                                <option value="Magnolia Blossom">Magnolia Blossom</option>
                                <option value="Lena Sandalwood">Lena Sandalwood</option>
                                <option value='Pineapple Sage'>Pineapple Sage</option>
                                <option value="Lime Basil Mango">Lime Basil Mango</option>
                                <option value="Lemon Drop">Lemon Drop</option>
                                <option value="Cheroot">Cheroot</option>
                                <option value="Teakwood and Cardamon">Teakwood and Cardamon</option>
                                <optoin value="Creole Musk">Creole Musk</optoin>

                                <option value="Chesterb Almondine">Chesterb Almondine</option>
                                <option value="Pumpkin Apple Pie">Pumpkin Apple Pie</option>
                                <option value="Amber">Amber</option>
                                <option value="Sage">Sage</option>
                            </select>
                        </form>

                        <form action="">
                            <select onChange={this.onSelectChangeTin2} value={this.state.tin2}>
                                <option value="NULL">Choose A Tin</option>
                                <option value="Almond Shea Butter">Almond Shea Butter</option>
                                <option value="Berry Blast">Berry Blast</option>
                                <option value="Coconut Palm">Coconut Palm</option>
                                <option value="Cotton Patch">Cotton Patch</option>
                                <option value="Crisp Linen">Crisp Linen</option>
                                <option value="Creme Brulee">Creme Brulee</option>
                                <option value="Lavender Fields">Lavender Fields</option>
                                <option value="Pound Cake">Pound Cake</option>
                                <option value="Mint Julep">Mint Julep</option>
                                <option value="Tropical Paradise">Tropical Paradise</option>
                                <option value="Vanilla Ribbons">Vanilla Ribbons</option>
                                <option value="Peach Passion">Peach Passion</option>
                                <option value="Eucalyptus">Eucalyptus</option>
                                <option value="Cheroot">Cheroot</option>
                                <option value="Magnolia Blossom">Magnolia Blossom</option>
                                <option value="Lena Sandalwood">Lena Sandalwood</option>
                                <option value='Pineapple Sage'>Pineapple Sage</option>
                                <option value="Lime Basil Mango">Lime Basil Mango</option>
                                <option value="Lemon Drop">Lemon Drop</option>
                                <option value="Cheroot">Cheroot</option> 

                                <option value="Chesterb Almondine">Chesterb Almondine</option>
                                <option value="Pumpkin Apple Pie">Pumpkin Apple Pie</option>
                                <option value="Amber">Amber</option>
                                <option value="Sage">Sage</option>
                            </select>
                        </form>
                    </div>
                )
            }
        }
    }

    luxBox = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname ==='Lux Box'){
                return(
                    <div>
                        <h1>
                            Lux Box
                        </h1>
                        <div className="hbcu-message">
                            Lux Box Themes
                        </div>
                        <form action="">
                            <select onChange={this.onSelectChangeTin1} value={this.state.tin1}>
                                <option value="NULL">Select A Theme</option>
                                <option value="Aromatherapy">Aromatherapy</option>
                                <option value="Fresh Sensations">Fresh Sensations</option>
                                <option value="Earthy Tones">Earthy Tones</option>
                                <option value="Sweet Sensations">Sweet Sensations</option>
                                <option value="Pumpkin Apple Pie">Pumpkin Apple Pie</option>
                            </select>
                        </form>
                    </div>
                )
            }
        }
    }

    fundraiser = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname ==='Fundraiser'){
                return(
                    <div>
                        <h1>
                            Fundraiser
                        </h1>
                        <div className="hbcu-message">
                            Choices
                        </div>
                        <form action="">
                            <select onChange={this.onSelectChangeTin1} value={this.state.tin1}>
                                <option value="NULL">Select A Scent</option>
                                <option value="Crisp Linen">Crisp Linen</option>
                                <option value="Tropical Paradise">Tropical Paradise</option>
                                <option value="Magnolia Blossom">Magnolia Blossom</option>
                                <option value="Lavender">Lavender</option>
                                <option value="Vanilla Ribbons">Vanilla Ribbons</option>
                                <option value="Pumpkin Apple Pie">Pumpkin Apple Pie</option>
                                <option value="Teakwood and Cardamom">Teakwood and Cardamom</option>
                            </select>
                        </form>
                    </div>
                )
            }
        }
    }

    divas = () =>{
        if(this.state.info != null)
        {
            if(this.state.info.productname ==='Divas in Dog Tags'){
                return(
                    <div>
                        <h1>
                            Scent Choices
                        </h1>
                        <div className="hbcu-message">
                            Choices
                        </div>
                        <form action="">
                            <select onChange={this.onSelectChangeTin1} value={this.state.tin1}>
                                <option value="NULL">Select A Scent</option>
                                <option value="Crisp Linen">Crisp Linen</option>
                                <option value="Pound Cake">Pound Cake</option>
                                <option value="Lavender">Lavender</option>
                                <option value="Magnolia">Magnolia</option>
                                <option value="Tropical Paradise">Tropical Paradise</option>
                                <option value="Teakwood Cardamom">Teakwood & Cardamom</option>
                            </select>
                        </form>
                    </div>
                )
            }
        }
    }

    divaAddon = () =>{
        if(this.state.info.productname ==='Divas in Dog Tags'){
            return(
                <div>
                    <p>
                    Teakwood & Cardamom - An exotic, masculine blend of teakwood surrounded by rich spices of cardamom, cinnamon, and clove; sitting on base notes of cedar, amber, sandalwood, and patchouli.
                    </p>

<p>
                    Magnolia Blossoms - A well rounded with top notes of mandarin, grapefruit, freesia, and hyacinth; middle notes of tuberose, magnolia, plum, and lily; all sitting on base notes of white musk and vanilla bean.
</p>
                    <p>
                    Tropical Paradise - A fresh tropical combination of pineapples, mango, papaya, coconut, and wild watermelon.
                    </p>

                    <p>
                    Pound Cake - A perfect blend of warm cake, almond, vanilla, and sugar.  
                    </p>

                    <p>
                    Crisp Linen - A clean accord with soft subtle back notes of powder and musk.
                    </p>

                    <p>

Lavender - Aromatherapy of  true lavender flowers. Brightens any room and helps you to maximize your zen.
                    </p>

                </div>
            )
        }
    }

    renderProduct()
    {
        if(this.state.info != null){
            if(this.isAvailable(this.state.info.startdate, this.state.info.enddate))
            {
                return(
                    <div>
                    <ProductDescription product={this.state} addon={this.divaAddon}/>
                    </div>
                )
            }
        }
    }
    
    render()
    {
        console.log(this.state);
        return(
            <div className="ProductPage">
                <SmallTitle/>
                <LockHeader shouldRenderCandleOpts={false}/>
                <div className="product-container">
                    {this.renderImageContainer()}
                    {this.renderProduct()}
                    {this.hbcuAddition()}
                    {this.valentineAddition()}
                    {this.birthday()}
                    {this.twoCannikins()}
                    {this.luxBox()}
                    {this.ShabbyChicPatioTrioAddition()}
                    {this.CitronellaColoredJars()}
                    {this.wedding()}
                    {this.fundraiser()}
                    {this.divas()}
                </div>
                <Footer/>
            </div>
        )
    }
}

export default ProductPage;