import React from 'react'
import { RemoveItem } from '../Models/Actions/RemoveItemAction'
import { connect } from 'react-redux'

class CartItem extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = { info: props.info }
        console.log(props.info)
    }

    renderRow = (props) =>{
        return(
            <tr>
                <td>
                    {props.data.info.productname}
                </td>
                <td>
                    {props.data.tin1}
                </td>
                <td>
                    ${props.data.info.productprice.toFixed(2)}
                </td>
                <td>
                    {props.units}
                </td>
                <button onClick={()=>{
                    this.props.RemoveItem({data: this.state})
                    this.props.getitem()
                }}>
                    X
                </button>
            </tr>
        )
    }

    render()
    {
        return(
            this.renderRow(this.state.info)
        )
    }
}

export default connect(null, { RemoveItem })(CartItem);
//export default CartItem;