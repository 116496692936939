import React from 'react'
import ItemTile from '../Components/ItemTile'
import host from '../Assets/host/host'
import '../Styles/CategoriesStyles.css'

class CategoriesPage extends React.Component
{
    category = 'speciality'

    constructor(props)
    {
        super(props)
        this.category = this.props.category
        this.state = {}
        
    }

    componentDidMount()
    {
        fetch(`${host}items/c/${this.category}`)
        .then(response => {
            return response.json()
        })
        .then(data => 
        {
            console.log(data)
            this.setState({info: data}) 
        })
        .catch((err) =>{
            if(err)
            {
                return this.error()
            }
        })
    }   

    specialityRoute(data)
    {
        if(this.state.info != null){
            return (
                <div className="special-container" id='speciality'>
                    <div className="category-title-container">
                        <h1 className='category-title-label'>
                            Speciality
                        </h1>
                    </div>
                    <div className="category-tile-item-container">
                        {
                        this.state.info.map(item =>{
                            return <ItemTile key={item.idproduct} info={item}/>
                        })
                        }
                    </div>
                </div>
            )
        }
    }

    collectionRoute(data)
    {
        if(this.state.info != null)
        {
            return (
            <div className="collection-container" id='collection'>
                <div className="category-title-container">
                    <h1 className='category-title-label'>
                        Collection
                    </h1>
                </div>
                <div className="category-tile-item-container">
                {
                        this.state.info.map(item =>{
                            return <ItemTile key={item.idproduct} info={item}/>
                        })
                        }
                </div>
            </div>
        )
        }
    }

    popularRoute(data)
    {
        if(this.state.info != null)
        { 
        return (
            <div className="popular-container" id='popular'>
                <div className="category-title-container">
                    <h1 className='category-title-label'>
                    Popular
                    </h1>
                </div>
                <div className="category-tile-item-container">
                {
                        this.state.info.map(item =>{
                            return <ItemTile key={item.idproduct} info={item}/>
                        })
                        }
                </div>
            </div>
        )
        }
    }

    featuredRoute(data)
    {
        if(this.state.info != null)
        {
        return (
            <div className="featured-container" id='featured'>
                <div className="category-title-container">
                    <h1 className='category-title-label'>
                    Featured
                    </h1>
                </div>
                <div className="category-tile-item-container">
                {
                    this.state.info.map(item =>{
                        if(!this.isAvailable(item.startdate, item.enddate))
                            return <ItemTile key={item.idproduct} info={item}/>
                        else
                            return <div></div>
                    })
                }
                </div>
            </div>
        )
        }
    }

    summerRoute(data)
    {
        console.log("summer route call")
        if(this.state.info != null)
        {
        return (
            <div className="featured-container" id='featured'>
                <div className="category-title-container">
                    <h1 className='category-title-label'>
                    Summer
                    </h1>
                </div>
                <div className="category-tile-item-container">
                {
                    this.state.info.map(item =>{
                        if(!this.isAvailable(item.startdate, item.enddate))
                            return <ItemTile key={item.idproduct} info={item}/>
                        else
                            return <div></div>
                    })
                }
                </div>
            </div>
        )
        }
    }

    winterRoute(data)
    {
        if(this.state.info != null)
        {
        return (
            <div className="winter-container" id='winter'>
                <div className="category-title-container">
                    <h1 className='category-title-label'>
                    Winter
                    </h1>
                </div>
                <div className="category-tile-item-container">
                {
                    this.state.info.map(item =>{
                        if(!this.isAvailable(item.startdate, item.enddate))
                            return <ItemTile key={item.idproduct} info={item}/>
                        else
                            return <div></div>
                    })
                }
                </div>
            </div>
        )
        }
        else
        {
            return this.error();
        }
    }

    fundraiserRoute(data)
    {
        if(this.state.info != null)
        {
        return (
            <div className="fundraiser-container" id='fundraiser'>
                <div className="category-title-container">
                    <h1 className='category-title-label'>
                    Fundraiser
                    </h1>
                </div>
                <div className="category-tile-item-container">
                {
                    this.state.info.map(item =>{
                        if(!this.isAvailable(item.startdate, item.enddate))
                            return <ItemTile key={item.idproduct} info={item}/>
                        else
                            return <div></div>
                    })
                }
                </div>
            </div>
        )
        }
        else
        {
            return this.error();
        }
    }

    error()
    {
        return (
            <div className="error-container">
                Loading...
            </div>
        )
    }

    renderRoute(route)
    {
        let data = []
        if(route === 'speciality')
        {
            return this.specialityRoute(data)
        }
        else if(route === 'collection')
        {
            return this.collectionRoute(data)
        }
        else if(route === 'popular')
        {
            return this.popularRoute(data)
        }
        else if(route === 'featured')
        {
            return this.featuredRoute(data)
        }
        else if(route === 'summer')
        {
            //return this.summerRoute(data)
        }
        else if(route === 'winter')
        {
            return this.winterRoute(data)
        }
        else if(route === 'fundraiser')
        {
            return this.fundraiserRoute(data)
        }
        else
        {
            console.log("Error render route")
        }
    }

    isAvailable(startDate, endDate)
    {
        let sd = new Date(startDate.replace(' ', 'T'))
        let ed = new Date(endDate.replace(' ', 'T'))
        let cd = new Date()
        return !(cd > sd && cd < ed)
    }

    render()
    {
        return(
            <div className="categories-container">
                <div className="route-containers">
                    {this.renderRoute(this.category)}
                </div>
            </div>
        )
    }
}

export default CategoriesPage;