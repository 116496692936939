import React from 'react';
import Title from '../Components/Title'
import LockHeader from '../Components/LockHeader'
import Footer from '../Components/Footer'
import Message from '../Components/Message'
import FeaturedItem from '../Components/FeaturedItem'
import '../Styles/Homepage.css';
import CHF_Logo from '../Assets/Images/CHF-Primary_RGB.jpg'
import CategoriesPage from '../Pages/CategoriesPage'
import Banner from '../Components/Banner';
import FloatingButton from '../Components/FloatingButton';
import owner from '../Assets/Images/Owner.JPG'

class Homepage extends React.Component {

  constructor(props)
    {
        super(props)
        this.state = { 
          windowWidth: window.innerWidth,
          baseCandle: document.getElementById('speciality')
        }
    }

    handleResize = (e) =>{
        this.setState({
            ...this.state,
            windowWidth: window.innerWidth
        })
    }

    componentDidMount()
    {
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount()
    {
        window.addEventListener('resize', this.handleResize)
    }

    moveToCandleSelection(element){
        var spec = document.getElementById(element)
        window.scrollTo({
            top: spec.offsetTop,
            left: 0,
            behavior: 'smooth'
        })
    }

    renderFloatButton()
    {
      return (
        <FloatingButton onclick={()=>{this.moveToCandleSelection('speciality')}}/>
      )
    }

    render()
    {
        return (
            <div className="Homepage">
              {this.renderFloatButton()}
              <LockHeader/>
              <Banner/>
              <Title/>
              <Message>
                <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <div style={{padding: '1rem'}}>
                <p>
                    Candle Royale was created with the consumer in mind. As a candle lover, I was always looking for a candle that remained fragrant until its last burn.  No matter how much I would spend, the level of satisfaction was often depleted and ultimately my quest continued.  
                    Crafting has always been an enjoyment in my pastime. As a wife and mother, juggling my career and home, I found myself not having dedicated time for it any longer.  But, through the years I’ve gradually gravitated back to it and now with a mission. Carving out some free time has reignited the spark to get back in the laboratory and experiment with wax and fragrance to produce quality candles with a purpose. 
                </p>

                <p>
                    Candle Royale offers a variety of fragrant, handcrafted, lux candles.  Our candles have been grouped in four categories for shopping convenience: Specialty, Collection, Popular and Featured.   Made of the highest quality soy wax and fragrance oils, they are all natural and ecofriendly.
                </p>
                </div>
                <div>
                <img src={owner} alt="" width='240px' style={{border: '2px solid #fff'}}/>
                <div style={{fontStyle: "italic"}}>
                Dr. Camacia Smith-Ross, Owner
                </div>
                </div>
                </div>

              </Message>
              <FeaturedItem link='/info' image={CHF_Logo}/>
              <Message>
                <h1 className='message-header'>Bulk Orders</h1>
              Do you have a memorable event planned?  Candle Royale can help you create that perfect candle keepsake. Scents are a constant reminder to help commemorate timeless events such as weddings, showers, parties, etc. Let us complement your special day. Please contact us for further details.
              </Message>

              <Message>
              <h1 className='message-header'>
              Fundraising 
              </h1>
Let Candle Royale help you plan your next fundraising event. Select or create a custom candle to sell that is reflective of your business, school, or organization and start raising money. We can meet all of your needs! Please contact us for further details.
              </Message>

              <Message>
              <h1 className='message-header'>
              The Sniff, Smell, Savor Experience 
              </h1>
Your place or ours! Join us to create your very own custom product. Candle Royale is ready to entertain your group with a candle making experience that is guaranteed to be a forever memorable event. Please contact us for further details. 
              </Message>
              <div className="category-button-container">
                <div className="center-box">
                  <CategoriesPage category='speciality'/>
                  <CategoriesPage category='collection'/>
                  <CategoriesPage category='popular'/>
                  <CategoriesPage category='featured'/>
                  <CategoriesPage category='summer'/>
                  <CategoriesPage category='winter'/>
                  <CategoriesPage category='fundraiser'/>
                </div>
              </div>
              <Footer/>
            </div>
          );
    }
}

export default Homepage;
