import React from 'react';
import '../Styles/ItemTile.css'
import { imageMap } from '../Models/ImageModel'
import { NavLink } from 'react-router-dom';

class ItemTile extends React.Component
{
    imageSize = 200
    temp = `https://via.placeholder.com/${this.imageSize}`;
    tileTitle = 'Place Holder Title'
    descrTitle = 'Description Place Holder Description Place Holder Description Place Holder Description Place Holder '
    id = 0

    constructor(prop)
    {
        super(prop)
        if(prop.info != null)
        {
            this.tileTitle = prop.info.productname
            this.descrTitle = prop.info.productdescription
            this.temp = imageMap[prop.info.prodcutimagename]
            this.id = prop.info.idproduct
        }
    }

    filterTitle(tileTitle)
    {
        return tileTitle.slice(0, 99);
    }

    isAvailable(startDate, endDate)
    {
        
        let sd = new Date(startDate.replace(' ', 'T'))
        let ed = new Date(endDate.replace(' ', 'T'))
        let cd = new Date()
        return !(cd > sd && cd < ed)
    }

    showTextOverlay(startDate, endDate)
    {
        if(this.isAvailable(startDate, endDate))
        {
            return(
                <div className="overlay-text">
                    Available {this.getMonth(startDate)}
                </div>
            )
        }
    }

    getMonth(date)
    {
        let currentdate = new Date(date.replace(' ', 'T'))
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[(currentdate.getMonth() + 1) % 12]
    }

    getClassName(startDate, endDate)
    {
        return this.isAvailable(startDate, endDate) ? 'item-tile-available-v2' : 'item-tile-image-container-v2'
    }

    linkManager = (e) => {
        if(this.isAvailable(this.props.info.startdate, this.props.info.enddate))
        {
            e.preventDefault()
        }
    }

    renderMonth()
    {
        if(this.props.info.productcategory === 'collection'){
            return this.getMonth(this.props.info.startdate)
        }
    }

    render()
    {
        return(
            <NavLink onClick={this.linkManager} className='link-container' exact to={`product/${this.id}`}>
                <div className='item-tile-container-v2'>
                    <div className={this.getClassName(this.props.info.startdate, this.props.info.enddate)}>
                        <img className='item-image-circle-v2' src={this.temp} alt="" width={this.imageSize} height={this.imageSize}/>
                    </div>
                    <div className="item-tile-title-v2">
                        {this.filterTitle(`${this.tileTitle}`)}
                        <div>
                        { this.renderMonth() }
                        </div>
                    </div>
                </div>
            </NavLink>
        )
    }
}

export default ItemTile;