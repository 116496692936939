import React from 'react';

import { Link } from 'react-router-dom'
import '../Styles/Title.css';
import logo from '../Assets/Images/candle_royale_2.png'

class SmallTitle extends React.Component
{
    render()
    {
        return(
            <Link className='headerStyle' to='/'>
                <div className="main-title-container-small">
                    <div className="logo-container-small">
                        <img src={logo} alt='' height='100%'/>
                    </div>
                    <div className="text-container">
                        <div className = 'title-container'>
                            <div className = 'title-text'>
                                <h1>
                                    Candle Royale
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}

export default SmallTitle;
