import React from 'react';
import Cart from './Cart';
import { NavLink } from 'react-router-dom';
import '../Styles/LockHeader.css';
import Icon from '../Components/Icon'
import twitter from '../Assets/Images/twitter.png'
import facebook from '../Assets/Images/facebook.png'
import instagram from '../Assets/Images/instagram.png'

class LockHeader extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = { windowWidth: window.innerWidth }
    }

    handleResize = (e) =>{
        this.setState({
            ...this.state,
            windowWidth: window.innerWidth
        })
    }

    componentDidMount()
    {
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount()
    {
        window.addEventListener('resize', this.handleResize)
    }

    moveScroll(element)
    {
        var spec = document.getElementById(element)
        window.scrollTo({
            top: spec.offsetTop,
            left: 0,
            behavior: 'smooth'
        })
    }

    renderMidPoint()
    {
        if(this.props.shouldRenderCandleOpts === undefined)
        {
            if(this.state.windowWidth > 768)
            {
                return(
                    <div className='jump-to-container'>
                        Browse our Candles--
                        <div className="mid-item bold" onClick={()=>{
                            this.moveScroll('speciality')
                        }}>
                        Speciality,
                        </div>

                        <div className="mid-item bold" onClick={()=>{
                            this.moveScroll('collection')
                        }}>
                        Collection,
                        </div>

                        <div className="mid-item bold" onClick={()=>{
                            this.moveScroll('popular')
                        }}>
                        Popular,
                        </div>

                        <div className="mid-item bold" onClick={()=>{
                            this.moveScroll('featured')
                        }}>
                        Featured
                        </div>
                    </div>
                )
            }
            else{
                return(
                    <div>
                        <div className="move-to-candle mid-item bold" onClick={()=>{
                            this.moveScroll('speciality')
                        }}>
                            Candles
                        </div>
                    </div>
                )
            }
        }
    }

    lockedHeader()
    {        
        return (
            <div className='main-header-container-fixed' id='header-container'>
                <div className="left-header-container">
                    <Icon image={twitter} link={'https://twitter.com/candleroyale1'} size={25}/>
                    <Icon image={facebook} link={'http://www.facebook.com'} size={25}/>
                    <Icon image={instagram} link={'https://www.instagram.com/candleroyale_/?hl=en'} size={25}/>
                    <div className="photo-gallery-text">
                        <NavLink className='link-container bold' exact to={`gallery`}>
                            Photo Gallery
                        </NavLink>
                    </div>
                </div>
                <div className="mid-header-container">
                {this.renderMidPoint()}
                </div>
                <div className="right-header-container">
                    <Cart/>
                </div>
            </div>
        )
    }

    render()
    {
        return this.lockedHeader()
    }
}

export default LockHeader;