import React from 'react'
import '../Styles/Footer.css'
class Footer extends React.Component
{
    render()
    {
        return (
            <div className="footer-container">
                <p>
                    Contact at --
                </p>
                
                <p>
                    Email: candleroyale2020@gmail.com
                </p>
                
                <p>
                    Phone: 504-884-2288
                </p>
                <p>
                    Designer of the site and Queries: cjahwaanwebsites@gmail.com
                </p>
            </div>
        )
    }
}

export default Footer;