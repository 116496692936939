import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, NavLink } from 'react-router-dom'
import { Provider } from 'react-redux'
import Homepage from './Pages/Homepage'
import ProductPage from './Pages/ProductPage'
import CategoriesPage from './Pages/CategoriesPage'
import InformationPage from './Pages/InformationPage'
import CheckoutPage from './Pages/CheckoutPage'
import CheckoutPageComplete from './Pages/CheckoutPageComplete'
import PhotoGalleryPage from './Pages/PhotoGalleryPage'
import './App.css';

import store from './Models/Store'

function App() {
  return (
    <Provider store={store}>
    <div className="App"> 
      <Router>
        <Switch>
          <Route path='/product' exact component={CategoriesPage}>
            <Redirect path='/' to='/'/>
          </Route>
          <Route path='/product/:id' exact component={ProductPage}/>
          <Route path='/info' exact component={InformationPage}/>
          <Route path='/checkout' exact component={CheckoutPage}/>
          <Route path='/checkout/complete' exact component={CheckoutPageComplete}/>
          <Route path='/gallery' exact component={PhotoGalleryPage}/>
          <Route path='/' exact component={Homepage}/>
          <Route render={()=>{
            return(
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <NavLink className='link-container' exact to={`/`}>
                  404 Page not found...sorry
                </NavLink>
              </div>
            )
          }}/>
        </Switch>
      </Router>
    </div>
    </Provider>
  );
}

export default App;