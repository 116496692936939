import React from 'react'
import '../Styles/ImageContainer.css'

class ImageContainer extends React.Component
{
    mainImageSize = 450;
    mainImage = `https://via.placeholder.com/${this.mainImageSize}`;

    constructor(prop)
    {
        super(prop)
        this.mainImage = prop.mainImage
    }

    render()
    {
        if(window.innerWidth < 480){ this.mainImageSize = 300}
        return(
            <div className="item-image-container">
                <div className="main-image-container">
                    <img src={this.mainImage} alt="" width={this.mainImageSize} height={this.mainImageSize} />
                </div>
                <div className="subtext">
                    {this.props.subtext}
                </div>
            </div>
        )
    }
}

export default ImageContainer;