import React from 'react';
import Message from '../Components/Message'
import LockHeader from '../Components/LockHeader'
import Footer from '../Components/Footer'
import SmallTitle from '../Components/SmallTitle'
import ImageContainer from '../Components/ImageContainer'
import '../Styles/Homepage.css';

import diaz from '../Assets/Images/Diaz.jpg'
import shinbone from '../Assets/Images/Shinbone.jpg'
import chriswittich from '../Assets/Images/ChrisWittich.jpg'

class InformationPage extends React.Component {

    render()
    {
        return (
            <div className="Information-page">
              <LockHeader/>
              <SmallTitle/>
                <Message>
                The Children’s Heart Foundation candle has been selected as the signature candle of the Candle Royale brand.  Ten percent of this candle’s sales will be donated to advance the diagnosis, treatment and prevention of congenital heart defects (CHDs) by supporting the funding of the most promising research. 
In 1994, we were expecting our first child.  What a promising time in our lives! As young, career driven professionals, we wanted to make sure that we were doing everything possible to ensure the birth of a healthy baby. During my second trimester, I begin to experience unfathomable pain and instantly knew something was wrong.  As a precautionary measure, my husband and I decided we should immediately go to the hospital to have a wellness visit. After being evaluated by an emergency room doctor, we were rushed to Children’s Hospital in New Orleans for additional testing. Results of many tests revealed that our baby was in distress and was formally diagnosed with Hypoplastic Left Heart Syndrome, a congenital heart defect, and only a transplant would give him a 50% survival rate. 
Candle Royale’s Sugar Pizzelle candle is in loving memory of Baby Ross. We are deeply committed to this research and supporting The Children’s Heart Foundation through fundraising.  Help us reach our goal of one million dollars to The Children’s Heart Foundation and enjoy the sweet scents of Sugar Pizzelle. A candle with a true purpose!   
                </Message>
              <div className="flex flex-left">
                
              </div>

              <div className="flex flex-center">
                <ImageContainer mainImage={diaz} subtext={'Copyright Diaz Photography'}/>
                <ImageContainer mainImage={shinbone} subtext={'Copyright Shinbone Photography'}/>
                <ImageContainer mainImage={chriswittich} subtext={'Copyright Chris Wittich Photography'}/>
              </div>

              <div className="flex flex-right">
                
              </div>

              <div className="video-container">
                <iframe title='childheart' width="560" height="315" src="https://www.youtube.com/embed/LxVIxApzpmw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <Footer/>
            </div>
          );
    }
}

export default InformationPage;
