import React from 'react'
import '../Styles/Message.css'

class Message extends React.Component
{
    render()
    {
        return(
            <div className='message-container'>
                <div className="message-items-container">
                    {this.props.children}
                </div>                
            </div>
        )
    }
}

export default Message;