import React from 'react'
import '../Styles/Banner.css'
import { NavLink } from 'react-router-dom';
import image_1 from '../Assets/Images/Candles/Restore.jpg'
import image_2 from '../Assets/Images/Candles/peabody.jpeg'

class Banner extends React.Component{
    banner_label = ''//'It’s Fall Fundraising Time! Earn BIG profits with us!'
    banner_id_1 = 62;
    banner_id_2 = 38;
    divasTagsImage = 'https://isteam.wsimg.com/ip/bd5a8be5-cae0-4f84-a7c7-8100df1406b7/Divas%20in%20Dog%20Tags%20New%20Logo%20%20with%20chain%20042920.jpg/:/rs=w:800,cg:true,m'

    isAvailable(startDate, endDate)
    {
        let sd = new Date(startDate.replace(' ', 'T'))
        console.log(sd)
        let ed = new Date(endDate.replace(' ', 'T'))
        console.log(ed)
        let cd = new Date()
        console.log(cd)
        return (cd > sd && cd < ed)
    }

    renderBannerLabel()
    {
        if(this.isAvailable('2021-10-14 00:00:00', '2021-10-31 23:59:59'))
        {
            return "Use code birthday for 20% off"
        }
        else if(this.isAvailable('2021-09-30 00:00:00','2021-10-13 23:59:59'))
        {
            return 'Order Here to support the Elite Onyx Cheer Association September 30, 2021 - October 13, 2021 Use code ONYXCHEER for 25% off'
        }
        else if(this.isAvailable('2021-11-26 00:00:00', '2021-11-26 23:59:59') || this.isAvailable('2021-11-29 00:00:00', '2021-11-29 23:59:59'))
        {
            return "Cyber Monday: Use code 'SALE' for 25% off "
        }
        else if(this.isAvailable('2022-08-22 00:00:00', '2021-08-31 23:59:59'))
        {
            return "Peabody Graduate Scholarship Fundraiser"
        }
        else if(this.isAvailable('2022-12-24 00:00:00', '2022-12-25 23:59:59'))
        {
            return "Merry Christmas"
        }

        return 'Contact us for your next event!'
    }

    renderBanner()
    {
        const { innerWidth: width } = window;
        if(width > 900)
        {
            return(
                <div className="banner_height banner_background_color banner_flex">
                    <div className="content_color">
                    {this.renderBannerLabel()}
                    </div>
                    <NavLink onClick={this.linkManager} className='link-container' exact to={`product/${this.banner_id_1}`}>
                    <div className="banner_image_container">
                        <img className="banner_image" src={image_1} alt="" />
                    </div>
                    </NavLink>
                    
                    <NavLink onClick={this.linkManager} className='link-container' exact to={`product/${this.banner_id_2}`}>
                    <div className="banner_image_container">
                        <img className="banner_image" src={image_2} alt={image_1} />
                    </div>
                    </NavLink>
                </div>
            )
        }
        else
        {
            return(
                <div className="banner_height banner_background_color banner_flex">
                    <div className="content_color">
                    {this.renderBannerLabel()}
                    </div>
                </div>
            )
        }
    }

    render()
    {
        return(
            <div>
                {this.renderBanner()}
            </div>
        )
    }
}

export default Banner