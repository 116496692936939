import React from 'react';
import { connect } from 'react-redux'
import '../Styles/Homepage.css';
import '../Styles/CheckoutPage.css'
import { GetItem } from '../Models/Actions/GetItemAction'
import { RemoveItem } from '../Models/Actions/RemoveItemAction'
import CheckoutTable from '../Components/CheckoutTable'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCheckoutForm from '../Components/StripeCheckoutForm'
import PaypalCheckoutForm from '../Components/PaypalCheckoutForm'
import host from '../Assets/host/host'

class CheckoutPage extends React.Component {
    
    stripePromise = loadStripe("pk_live_51HTHqaDBA6GX4mrz6gEBuUu2V00KTf9HguAUePqKYiao6vPgJCSGhfDEwgTOrOART9RX2RssRLWwDzj2o5HpMT6000nur2rXYC");

    constructor(props)
    {
        super(props)
        this.state = { 
            tabID: 0,
            nameInformation: {
                fname: '',
                lname: '',
            },
            addressInformation: {
                streetAddress: '',
                city: '',
                state: '',
                zipcode: '',
            },
            email: '',
            coupon: {
                value: '',
                discount: 0
            },
            cart: this.props.items,
            loading: false,
            shipping: true,
            toggleLoading: this.toggleLoading
        }
    }

    paypal = () =>{
        return(
            <div className="paypal-content-area">
                <PaypalCheckoutForm details={this.state}/>
            </div>
        )
    }

    stripe = () => {
        return(    
            <Elements stripe={this.stripePromise}>
                <div className="stripe-content-area">
                    <div className="customerInformation">
                        <div className="customerName">
                            <div className="firstName littlemargin">
                                <input value={this.state.nameInformation.fname} onChange={this.onChangeFName} type="text" placeholder='First Name'maxLength={45}/>
                            </div>
                            <div className="lastName littlemargin">
                                <input value={this.state.nameInformation.lname} onChange={this.onChangeLName} type="text" placeholder='Last Name'maxLength={45}/>
                            </div>
                            <div className="email littlemargin">
                                <input value={this.state.nameInformation.email} onChange={this.onChangeEmail} type='text' placeholder='Email'maxLength={45}/>
                            </div>
                        </div>
                        <div className="address">
                            <div className="streetAddress littlemargin">
                                <input value={this.state.addressInformation.streetAddress} onChange={this.onChangeStreetAddress} type="text" placeholder='Street Address'maxLength={45}/>
                            </div>
                            <div className="zipcode littlemargin">
                                <input value={this.state.addressInformation.zipcode} onChange={this.onChangeZipcode} type="text" placeholder='Zipcode' maxLength={5}/>
                            </div>
                            <div className="city littlemargin">
                                <input value={this.state.addressInformation.city} onChange={this.onChangeCity} type="text" placeholder='City'maxLength={45}/>
                            </div>
                            <div className="state littlemargin">
                                <input value={this.state.addressInformation.state} onChange={this.onChangeState} type="text" placeholder='State' maxLength={45}/>
                            </div>
                        </div>
                    </div>
                    <StripeCheckoutForm details={this.state}/>
                </div>
            </Elements>
        )
    }

    getSubtotal = () =>{
        let total = 0
        if(this.props.items.length !== 0){
            for(let i = 0; i < this.props.items.length; i++)
            {
                console.log(`${JSON.stringify(this.props.items[i])}`)
                console.log(`Price: ${this.props.items[i].data.info.productprice} -- Units: ${this.props.items[i].units}`)
                total += Number.parseFloat(this.props.items[i].data.info.productprice) * Number.parseFloat(this.props.items[i].units)
            }
        }
        console.log(`get subtotal: ${total}`)
        return total
    }

    getTax = (total) =>{
        return 0.10 * total
    }

    getTotal = () => {
        let tax = Number.parseFloat( this.getTax(this.getSubtotal()) )
        let sub = Number.parseFloat( this.getSubtotal() )
        let ship = Number.parseFloat( this.calculateShipping() )
        
        console.log(`tax: ${tax}, sub: ${sub}, ship: ${ship}`)

        console.log(`tax: ${this.getTax(this.getSubtotal())}, sub: ${this.getSubtotal()}, ship: ${this.calculateShipping()}`)
 
        if(this.state.shipping)
            return sub + tax + ship
        else
            return sub + tax
    }

    calculateShipping = () => {
        let totalUnits = 0
        for(let i = 0; i < this.props.items.length; i++){
            totalUnits += this.props.items[i].units
        }
        let ship = (Number.parseInt(totalUnits / 3) + 1) * 8.50
        return this.state.shipping ? ship : 0.00
    }

    renderPaymentOptions = () =>
    {
        return(
            <div className="paymentInformation">
                <div className="btn-tab">
                    <button className="paypal-btn" onClick={this.paypalButtonClick}>
                        Paypal
                    </button>
                    <button className="stripe-btn" onClick={this.stripeButtonClick}>
                        Stripe
                    </button>
                </div>
                    
                <div className="payment-content-area">
                    {this.state.tabID === 0 ? this.paypal() : this.stripe()}
                </div>
            </div>
        )
    }

    paypalButtonClick = () =>
    {
        this.setState({ tabID : 0 })
    }

    stripeButtonClick = () =>
    {
        this.setState({ tabID : 1 })
    }

    onChangeFName = (event) =>
    {
        let substate = this.state
        substate.nameInformation.fname = event.target.value
        this.setState(substate)
    }

    onChangeLName = (event) =>
    {
        let substate = this.state
        substate.nameInformation.lname = event.target.value
        this.setState(substate)
    }

    onChangeStreetAddress = (event) =>
    {
        let substate = this.state
        substate.addressInformation.streetAddress = event.target.value
        this.setState(substate)
    }

    onChangeCity = (event) =>
    {
        let substate = this.state
        substate.addressInformation.city = event.target.value
        this.setState(substate)
    }

    onChangeZipcode = (event) =>
    {
        let substate = this.state
        substate.addressInformation.zipcode = event.target.value
        this.setState(substate)
    }

    onChangeState = (event) =>
    {
        let substate = this.state
        substate.addressInformation.state = event.target.value
        this.setState(substate)
    }

    onChangeCoupon = (event) =>
    {
        let substate = this.state
        substate.coupon.value = event.target.value
        this.setState(substate)
    }

    onChangeEmail = (event) =>{
        let substate = this.state
        substate.email = event.target.value
        this.setState(substate)
    }

    isStringAnEmail = (value) =>{
        let reg = /(\w+(\.*)\w+)@(\w+)\.((com)|(edu)|(net)|(org))/
        return reg.test(value)
    }

    isStringAZip = (value) =>{
        let reg = /\d{5}/
        return reg.test(value)
    }

    toggleShipping = () =>{
        if(this.state.shipping){
            let substate = this.state
            substate.shipping = false
            this.setState(substate)
        }
        else{
            let substate = this.state
            substate.shipping = true
            this.setState(substate)
        }
    }

    renderView(){
        return(
            <div className="CheckoutPage">
                <div className="centerContent margin">
                    <h1 className='checkoutLabel'>
                        Checkout
                    </h1>
                    <div className="itemsToCheckout">
                        <CheckoutTable 
                            info={this.state.cart} 
                            discount={this.state.coupon.discount} 
                            shipping={this.calculateShipping()}/>
                    </div>
                    <div className="coupon littlemargin">
                        <input className='coupon-input' value={this.state.coupon.value} onChange={this.onChangeCoupon} type="text" placeholder='Coupon'/>
                        <button onClick={this.checkCoupon} className='coupon-btn'>Check</button>
                    </div>
                    <div>
                        Do you want Shipping: {this.state.shipping ? <div onClick={this.toggleShipping} className='hover'>yes</div> : <div onClick={this.toggleShipping} className='hover'>no</div>}
                    </div>
                    {this.renderPaymentOptions()}
                </div>
            </div>
        )
    }

    toggleLoading = () =>{
        let substate = this.state;
        if(substate.loading === true)
            substate.loading = false
        else
            substate.loading = true
        this.setState(substate)
    }

    renderLoading(){
        return (
            <div className='fillscreen'>
                <div className="loader"></div>
            </div>
        )
    }

    checkCoupon = () =>{
        let substate = this.state;
        substate.loading = true
        this.setState(substate)

        let coupon = { }

        fetch(`${host}coupon/check`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: substate.coupon.value,
                cart: JSON.stringify(this.props.items),
                currentTotal: this.getTotal(),
                shippingCost: this.calculateShipping(),
                currentSubtotal: this.getSubtotal(),
            })
        })
        .then(response => {return response.json()})
        .then(json => {
            coupon.accepted = json.accepted
            coupon.discount = -json.discount

            if(coupon.accepted === true){
                console.log('coupon accepted')
            }

            substate.coupon.discount = coupon.discount
            substate.loading = false
            this.setState(substate)
        })
    }

    componentDidUpdate(){

    }

    render()
    {
        console.log(this.state.cart)
        return (
            <div>
                {this.renderView()}
                {/*this.state.loading ? this.renderLoading() : <div></div> */}
            </div>
        );
    }
}


const mapStateToProps = state => ({
    items: state.addItemReducer.cartItems
})

export default connect(mapStateToProps, { GetItem, RemoveItem })(CheckoutPage);
