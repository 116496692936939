import React from 'react'
import '../Styles/FloatingButton.css'

class FloatingButton extends React.Component{
    render(){
        return (
            <div className='floating-button-container'>
                <div className="floating-button" onClick={this.props.onclick}>
                    Candles
                </div>
            </div>
        )
    }
}

export default FloatingButton