import { PayPalButton } from 'react-paypal-button-v2'
import React from 'react'
import host from '../Assets/host/host'
import { Redirect } from 'react-router'
import '../Styles/CheckoutPage.css'

class PaypalCheckoutForm extends React.Component{
    constructor(props)
    {
        super(props)
        this.state = { shouldRedirect: false, streetAddress: '', zipcode: '', city: '', state: '' }
    }

    redirectOnComplete(){
        if(this.state.shouldRedirect){
            return (
                <Redirect to='/checkout/complete'/>
            )
        }
    }

    toggle = () =>{
        this.props.details.toggleLoading()
    }

    onchangeAddress = (event) =>{
        let temp = event.target.value
        this.setState((state)=>{return {...state, streetAddress: temp}})
    }

    onchangeZipcode = (event) =>{
        let temp = event.target.value
        this.setState((state)=>{return {...state, zipcode: temp}})
    }

    onchangeCity = (event) =>{
        let temp = event.target.value
        this.setState((state)=>{return {...state, city: temp}})
    }

    onchangeState = (event) =>{
        let temp = event.target.value
        this.setState((state)=>{return {...state, state: temp}})
    }
    
    render(){
        return(
            <div >
                <div className="paypal-info-content-area">
                    <input onChange={this.onchangeAddress}  value={this.state.streetAddress} type="text" placeholder='street address'/>
                    <input onChange={this.onchangeZipcode}  value={this.state.zipcode} type="text" placeholder='zipcode' maxLength={5}/>
                    <input onChange={this.onchangeCity}     value={this.state.city} type="text" placeholder='city'/>
                    <input onChange={this.onchangeState}    value={this.state.state} type="text" placeholder='state'/>
                </div>
                <PayPalButton
                createOrder={
                    async (data, actions)=>{
                        this.toggle()
                        let cost = 0
                        cost = await fetch(`${host}payment/paypal-payment-intent`,{
                            method: 'POST',
                            headers:{
                                'Content-Type' : 'application/json'
                            },
                            body: JSON.stringify({
                                orderdetails: this.props.details
                            })
                        })
                        .then(response => response.json())
                        .then(json =>{
                            cost = json.cost
                            return cost
                        })

                        return actions.order.create({
                            intent: 'CAPTURE',
                            purchase_units: [{
                                amount: {
                                    currency_code: 'USD',
                                    value: cost
                                }
                            }]
                        })
                    }
                }
                onSuccess={
                    (data, action)=>{
                        fetch(`${host}payment/paypal-payment-complete`,{
                            method: 'POST',
                            headers:{
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                receipt: data,
                                address: this.state,
                                cart: this.props.details.cart
                            })
                        })
                        .then(response => response.json())
                        .then(json =>{

                        })
                        this.setState(state=>{ return {...state, shouldRedirect: true}})
                    }
                }
                onError={
                    (err)=>{
                        console.log(`***Error***: ${err}`)
                    }
                }
                options={
                    { 
                        clientId: 'Ae0Vh-RSaDu0yY9SzNijnJ8EZRrCpk0mFbo-MYhjlXEHHNuH4ZFXRIDnDeTySTQ5hFGWPwE1vDt-boz1',
                        currency: 'USD',
                    }
                }
                />
                {this.redirectOnComplete()}
            </div>
        )
    }
}

export default PaypalCheckoutForm