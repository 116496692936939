import React from 'react';
import '../Styles/Title.css';
import logo from '../Assets/Images/candle_royale_2.png'

class Title extends React.Component
{
    render()
    {
        return(
            <div className="main-title-container">
                <div className="logo-container">
                    <img className='logo' src={logo} alt=''/>
                </div>
                <div className="text-container">
                    <div className = 'title-container'>
                        <div className = 'title-text'>
                            <h1>
                                Candle Royale
                            </h1>
                        </div>
                    </div>
                    <div className='subtitle-container'>
                        <div className="subtitle-text">
                            Sniff, Smell, Savor
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Title;
