import React from 'react'
import { Link } from 'react-router-dom'
import { ClearCart } from '../Models/Actions/ClearCartAction'
import { connect } from 'react-redux'

class CheckoutPageComplete extends React.Component{
    render()
    {
        this.props.ClearCart()
        return(
            <div style={{display: 'flex', flexGrow: '1', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <h1>
                    Thank You for your purchase!
                </h1>
                <Link to='/'>
                    Home
                </Link>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    items: state.addItemReducer.cartItems
})

export default connect(mapStateToProps, { ClearCart })(CheckoutPageComplete);