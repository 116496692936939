import React from 'react'
import CartItem from './CartItem'
import { GetItem } from '../Models/Actions/GetItemAction'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import '../Styles/Cart.css'

class Cart extends React.Component
{
    cartState = {
        isOpen : false,
    }

    constructor(props)
    {
        super(props)
        this.state = this.cartState
    }

    componentDidMount()
    {
        this.props.GetItem()
    }

    componentDidUpdate()
    {
        this.props.GetItem()
    }

    renderCircleItemCount(items)
    {
        return (
            <div className="circle-container" style={circleContainerStyles}>
                {items}
            </div>
        )
    }

    renderCartItems()
    {
        if(this.props.items.length === 0)
        {
            return (
                <div style={emptyCartMessage}>
                    Cart Empty
                </div>
            )
        }
        return (
            <table>
                <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Scent
                        </th>
                        <th>
                            Cost
                        </th>
                        <th>
                            Units
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.items.map((element) => {
                        return <CartItem key={element.data.info.idproduct} getitem={this.clicked} info={element}/>
                    })}
                </tbody>
                
            </table>
        )
    }

    cartOpen()
    {
        return(
            <div className='cart-open-container'>
                {this.cartClosed()}
                <div style={openCartContainer}  className="items-container">
                    {this.renderCartItems()}
                    <Link className='checkoutLinkStyle' style={checkoutLinkStyle} to={'/checkout'}>
                        <div style={checkoutBtn} className="checkout-btn">
                            Checkout
                        </div>
                    </Link>
                </div>
            </div>
        )
    }

    cartClosed()
    {
        return (
            <div style={closeCartContainer} onClick={this.clicked}>
                <div style={closeCartLabel} className="close-cart-label">
                    Cart:
                </div>
                {this.renderCircleItemCount(this.props.items.length)}
            </div>
        )
    }

    renderCart()
    {
        if(this.state.isOpen)
        {
            return this.cartOpen()
        }
        else
        {
            return this.cartClosed()
        }
    }

    clicked = () =>
    {
        this.cartState.isOpen = this.cartState.isOpen ? false : true
        this.setState(this.cartState)
    }

    render()
    {
        return(
            <div className='cart-container'>
                {this.renderCart()}
            </div>
        )
    }
}
 
const circleContainerStyles = {
    borderRadius: '10em',
    backgroundColor: '#d14009',
    width: '2em',
    height: '2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
}

const closeCartLabel = {
    marginRight: '0.5em',
}

const closeCartContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const openCartContainer = {
    position: 'absolute',
    right: 0,
    backgroundColor: 'white',
    borderColor: 'black',
    borderRadius: '0.1rem',
    width: '30rem',
    marginRight: '1rem',
}

const emptyCartMessage = {
    padding: '1rem',
}

const checkoutBtn = {
    backgroundColor: '#d14009',
    color: 'white',
    padding: '1rem',
}

const checkoutLinkStyle = {
    textDecoration: 'inherit',
}

const mapStateToProps = state => ({
    items: state.addItemReducer.cartItems
})

export default connect(mapStateToProps, { GetItem })(Cart);
