import React from 'react'
import '../Styles/PhotoGallery.css'

import img0 from '../Assets/Images/Gallery/image0.jpeg'
import img1 from '../Assets/Images/Gallery/image1.jpeg'
import img2 from '../Assets/Images/Gallery/image2.jpeg'
import img3 from '../Assets/Images/Gallery/image3.jpeg'
import img4 from '../Assets/Images/Gallery/image4.jpeg'
import img5 from '../Assets/Images/Gallery/image5.jpeg'
import img6 from '../Assets/Images/Gallery/image6.jpeg'
import img7 from '../Assets/Images/Gallery/image7.jpeg'
import img8 from '../Assets/Images/Gallery/image8.jpeg'
import img9 from '../Assets/Images/Gallery/image9.jpeg'
import img10 from '../Assets/Images/Gallery/image10.jpeg'
import img11 from '../Assets/Images/Gallery/image11.JPG'
import img12 from '../Assets/Images/Gallery/image12.JPG'
import img13 from '../Assets/Images/Gallery/image13.JPG'
import img14 from '../Assets/Images/Gallery/image14.JPG'
import img15 from '../Assets/Images/Gallery/image15.JPG'
import img16 from '../Assets/Images/Gallery/image16.JPG'
import img17 from '../Assets/Images/Gallery/image17.JPG'
import img18 from '../Assets/Images/Gallery/image18.JPG'
import img19 from '../Assets/Images/Gallery/image19.JPG'
import img20 from '../Assets/Images/Gallery/image20.JPG'
import img21 from '../Assets/Images/Gallery/image21.JPG'
import img22 from '../Assets/Images/Gallery/image22.JPG'
import img23 from '../Assets/Images/Gallery/image23.JPG'
import img24 from '../Assets/Images/Gallery/image24.JPG'
import img25 from '../Assets/Images/Gallery/image25.JPG'
import img26 from '../Assets/Images/Gallery/image26.JPG'
import img27 from '../Assets/Images/Gallery/image27.JPG'
import img28 from '../Assets/Images/Gallery/image28.jpeg'
import img29 from '../Assets/Images/Gallery/image29.jpeg'
import img30 from '../Assets/Images/Gallery/image30.jpeg'

class PhotoGallary extends React.Component
{
    maxImageHeight = '500px'
    constructor(props)
    {
        super(props)
        this.state = {
            enlargeImage: false,
            currentImage: '',
        }
    }

    placePhoto(photo)
    {
        return (
            <div className='photo-gallery-photo-container'>
                <img onClick={()=>{
                    this.imageClick(photo)
                }}
                onLoad={this.OnImageLoad} 
                src={photo} 
                alt='https://via.placeholder.com/150'
                height='150px'
                />
            </div>
        )
    }

    imageClick(photo)
    {
        this.setState((state)=>{
            return{
                ...this.state,
                enlargeImage: true,
                currentImage: photo
            }
        })
    }

    renderPhotos()
    {
        return(
            <div className="photo-gallery-view-container">
                {this.placePhoto(img0)}
                {this.placePhoto(img1)}
                {this.placePhoto(img2)}
                {this.placePhoto(img3)}
                {this.placePhoto(img4)}
                {this.placePhoto(img5)}
                {this.placePhoto(img6)}
                {this.placePhoto(img7)}
                {this.placePhoto(img8)}
                {this.placePhoto(img9)}
                {this.placePhoto(img10)}
                {this.placePhoto(img11)}
                {this.placePhoto(img12)}
                {this.placePhoto(img13)}
                {this.placePhoto(img14)}
                {this.placePhoto(img15)}
                {this.placePhoto(img16)}
                {this.placePhoto(img17)}
                {this.placePhoto(img18)}
                {this.placePhoto(img19)}
                {this.placePhoto(img20)}
                {this.placePhoto(img21)}
                {this.placePhoto(img22)}
                {this.placePhoto(img23)}
                {this.placePhoto(img24)}
                {this.placePhoto(img25)}
                {this.placePhoto(img26)}
                {this.placePhoto(img27)}
                {this.placePhoto(img28)}
                {this.placePhoto(img29)}
                {this.placePhoto(img30)}
            </div>
            
        )
    }

    loadAllImages(imageList)
    {
        for(var image of imageList)
        {
            this.placePhoto(image)
        }
    }

    scrollToLoadedImage(){
        let overlay = document.getElementById('overlayImage')
        window.scrollTo({
            top: overlay.offsetTop,
            left: 0,
            behavior: 'smooth'
        })
    }

    renderOverlay()
    {
        if(this.state.enlargeImage)
        {
            return(
                <div onClick={()=>{
                    this.setState((state)=>{
                        return{
                            ...this.state,
                            enlargeImage: false,
                            currentImage: ''
                        }

                    })
                }} id='overlayImage' className="overlayImage">
                    <img
                    onLoad={this.scrollToLoadedImage}
                    src={this.state.currentImage} 
                    alt='https://via.placeholder.com/150'
                    height={this.maxImageHeight}
                    />
                </div>
            )
        }
    }

    render(){
        return(
            <div className="">
                {this.renderOverlay()}
                <div className="photo-gallery-title-container">
                    Photo Gallery
                </div>
                <div className='photo-gallery-main-container'>
                    {this.renderPhotos()}
                </div>
            </div>
        )
    }
}

export default PhotoGallary;