
let initalCartState = {
    cartItems: []
}

export default function (state = initalCartState, action)
{
    switch(action.type)
    {
        case 'add_cart':
            let isNew = true
            for(let e = 0; e < state.cartItems.length; e++)
            {
                console.log("Reducer-> state cart name " + state.cartItems[e].data.productname)
                console.log("Reducer-> action payload name: " + action.payload.data.productname)
                if(state.cartItems[e].data.info.productname === "Pick 2 Cannikins")
                {
                    isNew = true;
                }
                else if(state.cartItems[e].data.info.productname === "Lux Box")
                {
                    isNew = true;
                }
                else if(state.cartItems[e].data.info.productname === "Birthday")
                {
                    isNew = true;
                }
                else if(state.cartItems[e].data.info.productname === action.payload.data.info.productname)
                {
                    var tincheck1 = state.cartItems[e].data.tin1
                    var tincheck2 = action.payload.data.tin1
                    console.log(tincheck1)
                    console.log(tincheck2)
                    if(tincheck1 !== tincheck2)
                    {
                        console.log("new")
                        console.log(state.cartItems[e].data.tin1)
                        console.log(action.payload.data.tin1)
                        isNew = true;
                    }
                    else
                    {
                        isNew = false
                        state.cartItems[e].units += action.payload.units
                    }
                }
            }
            if(isNew)
                state.cartItems.push(action.payload)

            let newLoad = state.cartItems
            return {
                ...state,
                cartItems: newLoad
            }

        case 'get_cart':
            return state

        case 'clear_cart':
            state.cartItems = []
            return state

        case 'remove_cart':
            console.log('remove from cart item')
            console.log(action.payload.data.info.data.info)
            var obj = action.payload.data.info.data.info
            for(let e = 0; e < state.cartItems.length; e++)
            {
                if(state.cartItems[e].data.info.productname === obj.productname)
                {
                    console.log('removing item')
                    let out = state.cartItems.splice(e, 1)
                    console.log(out)
                }
            }

            console.log(state)

            let outLoad = state.cartItems
            return {
                ...state,
                cartItems: outLoad
            }

        default:
            return state
    }
}
