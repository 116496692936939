import React from 'react';
import LockHeader from '../Components/LockHeader'
import Footer from '../Components/Footer'
import PhotoGallery from '../Components/PhotoGallery'
import SmallTitle from '../Components/SmallTitle';

class PhotoGalleryPage extends React.Component {

    render()
    {
        return (
            <div className="Homepage">
              <LockHeader shouldRenderCandleOpts={false}/>
              <SmallTitle/>
              <PhotoGallery/>
              <Footer/>
            </div>
          );
    }
}

export default PhotoGalleryPage;
