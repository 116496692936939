import React from 'react'
import { Link } from 'react-router-dom'
import '../Styles/FeaturedTile.css'

class FeaturedItem extends React.Component
{
    imageSize = 200
    temp = `https://via.placeholder.com/${this.imageSize}`;

    render()
    {
        return(
            <div className='featured-item-container'>
                <div className="alignment-container">
                    <div className="image-container">
                       {// If props.link exist render the image as a link if not just the image
                        this.props.link ? 
                        <Link to={this.props.link}>
                            <img src={this.props.image} alt='' width={this.imageSize}/>
                        </Link> : 
                        <img src={this.props.image} alt='' width={this.imageSize}/>
                        }
                    </div>
                    <div className="feature-text-container">
                        <div className="description-container">
                        The Children’s Heart Foundation candle has been selected as the signature candle of the Candle Royale brand.  Ten percent of this candle’s sales will be donated to advance the diagnosis, treatment and prevention of congenital heart defects (CHDs) by supporting the funding of the most promising research.  
                        <Link to={this.props.link}>
                            Read More Here
                        </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeaturedItem;