import React from 'react';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import host from '../Assets/host/host'
import '../Styles/StripeCheckout.css'
import { Redirect } from 'react-router'

class CheckoutForm extends React.Component {
    constructor(props){
      super(props)
      this.state = {shouldRedirect: false}
    }

    toggle = () =>{
      console.log(this.props.details)
      this.props.details.details.toggleLoading()
    }

    handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const {stripe, elements, details} = this.props


    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
    (async () => {
        this.toggle()
        let intent = {}
        fetch(`${host}payment/stripe-payment-intent`, {
          method: 'POST',
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            orderdetails: this.props.details
          })
        })
        .then(response => response.json())
        .then(json =>{
            intent = json

            // Call stripe.confirmCardPayment() with the client secret.
            stripe.confirmCardPayment(intent.client_secret, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: details.nameInformation,
                  addres: details.addressInformation,
                  email: details.email,
                },
              }
            }).then((result)=>{
              if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                console.log(result.error.message);
              } else {
                  // The payment has been processed!
                  if (result.paymentIntent.status === 'succeeded') {
                      // Show a success message to your customer
                      // There's a risk of the customer closing the window before callback
                      // execution. Set up a webhook or plugin to listen for the
                      // payment_intent.succeeded event that handles any business critical
                      // post-payment actions.
                      console.log('succeeded')
                      fetch(`${host}payment/stripe-payment-complete`,{
                        method: 'POST',
                        headers:{
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                          orderdetails: this.props.details,
                          orderresult: result
                        })
                      })
                      .then(response => response.json())
                      .then(json => {
                        this.setState(state=>{return {shouldRedirect: true}})
                      })
                  }
              }
            });
        })
        .catch((err)=> console.log(err))
        
      })();
  };

  redirectOnComplete(){
    if(this.state.shouldRedirect){
        return (
            <Redirect to='/checkout/complete'/>
        )
    }
}

  render() {
    return (
        <form style={{width: '100%'}}onSubmit={this.handleSubmit}>
          <CardSection />
          <button className='confirmOrder-btn' disabled={!this.props.stripe}>Confirm order</button>
          {this.redirectOnComplete()}
        </form>
    );
  }
}

export default function InjectedCheckoutForm(details) {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm  stripe={stripe} elements={elements} details={details}/>
      )}
    </ElementsConsumer>
  );
}
