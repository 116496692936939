import React from 'react'
import '../Styles/CheckoutTable.css'

class CheckoutTable extends React.Component
{
    shipping = 5.00

    constructor(props)
    {
        super(props)
        this.shipping = this.props.shipping
        console.log(`Props: ${this.props.info}`)
    }

    renderRow = (props) =>{
        return(
            <tr>
                <td>
                    {props.data.info.productname}
                </td>
                <td>
                    {props.data.tin1}
                </td>
                <td>
                    ${props.data.info.productprice.toFixed(2)}
                </td>
                <td>
                    {props.units}
                </td>
            </tr>
        )
    }

    getSubtotal = () =>{
        let total = 0
        if(this.props.info.length !== 0){
            for(let i = 0; i < this.props.info.length; i++)
            {
                total += this.props.info[i].data.info.productprice * this.props.info[i].units
            }
        }

        return total
    }

    getTax = (total) =>{
        return 0.10 * total
    }

    getTotal = () => {
        let tax = Number.parseFloat( this.getTax(this.getSubtotal()) )
        let sub = Number.parseFloat( this.getSubtotal() )
        console.log(`Tax: ${tax} + Sub: ${sub} + Shipping: ${this.props.shipping} + Discount: ${this.props.discount} = Total: ${tax + sub + this.props.shipping + this.props.discount}`)
        return (sub + tax + this.props.shipping + this.props.discount) > 0 ? (sub + tax + this.props.shipping + this.props.discount) : 0
    }

    discount = () =>{
        if(this.props.discount > 0){
            return this.renderRow({name: 'discount', price: this.props.discount, 'units': 1})
        }
    }

    render()
    {
        return(
            <div className="tableContainer">
                <table>
                    <thead>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Scent
                            </th>
                            <th>
                                Cost
                            </th>
                            <th>
                                Units
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.info.map(element =>{
                            return this.renderRow(element)
                        })}
                        {this.discount()}
                    </tbody>
                    <thead>
                        <tr>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    <tr>
                        <td>
                            ${this.getSubtotal().toFixed(2)}
                        </td>
                    </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <th>Tax</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    <tr>
                        <td>
                            ${this.getTax(this.getSubtotal()).toFixed(2)}
                        </td>
                    </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <th>Shipping</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    <tr>
                        <td>
                            ${this.props.shipping.toFixed(2)}
                        </td>
                    </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <th>Discount</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    <tr>
                        <td>
                            ${this.props.discount.toFixed(2)}
                        </td>
                    </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <th>Total</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    <tr>
                        <td>
                            ${this.getTotal().toFixed(2)}
                        </td>
                    </tr>
                    </tbody>

                </table>
            </div>
        )
    }
}

export default CheckoutTable